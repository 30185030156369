/**
 *
 * NewPage
 *
 */

import React, { Suspense, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// import { Editor } from '@tinymce/tinymce-react';
import { Field, Form, Formik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled, { createGlobalStyle } from 'styled-components';

import FeaturedImageField from 'components/admin/FeaturedImageField';
import { Error, InputLabel } from 'components/admin/layout';
import DeleteModal from 'components/modals/DeleteModal';
import { Col, Grid, H2 } from 'components/layout';
import EmailNotificationButton from 'components/EmailNotificationButton';
import Loader from 'components/Loader';
import {
  makeSelectCategoriesBySlug,
  makeSelectRelationsBySlug,
  makeSelectTagsBySlug,
  makeSelectCurrentUser,
  makeSelectUserRoles,
} from 'containers/App/selectors';
import MediaGallery from 'containers/MediaGallery';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import TagsField from 'components/admin/TagsField';
import StatusField from 'components/admin/StatusField';
import CommentsStatusField from 'components/admin/CommentsStatusField';
import UnsavedPrompt from 'components/admin/UnsavedPrompt';
import {
  getFullCategoryBySlug,
  getFullTagBySlug,
  validateTitle,
  FormikEffect,
} from 'utils/formUtils';
import { slugify, urlBuilder } from 'utils/helpers';
import { useInjectSaga } from 'utils/injectSaga';
import { useModal, useCheckRole } from 'utils/hooks';
import {
  useEditableViaSocket,
  useModalEditors,
  usePhraseapp,
} from 'utils/adminHooks';
import { generateSettings } from 'utils/tinymce';
import FormActions from 'components/admin/FormActions';
import AuthorInfo from 'components/admin/AuthorInfo';
import ModalEditors from 'components/admin/ModalEditors';
import { LoadingButton } from 'components/admin/SaveButton';
import TranslationInfo from 'components/admin/TranslationInfo';
import PhrasePushModal from 'components/modals/PhrasePushModal';
import { ROLES } from 'utils/constants';
import RevisionsWidget from 'components/admin/RevisionsWidget';
import ReadConfirmationInfo from '../../../components/admin/ReadConfirmationInfo';
import request from '../../../utils/request';
import { setSaved } from '../AdminSection/actions';

import { makeSelectSavingState } from '../AdminSection/selectors';

import { saveNewAction, deleteNewAction } from './actions';
import saga from './saga';

const LazyTinyMCE = React.lazy(() => import('@tinymce/tinymce-react').then(module => ({ default: module.Editor })));

const GlobalStyle = createGlobalStyle`
  .tox-silver-sink {
    z-index: -1 !important;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export function AdminNewPage({
  article,
  loadNew,
  deleteNew,
  saveNew,
  match,
  tags,
  relations,
  categories,
  initEmptyNew,
  language,
  savingState,
  currentUser,
  roles,
  resetSaveState,
}) {
  useInjectSaga({ key: 'adminNewPage', saga });

  const { loading } = article || {};
  const [data, setData] = useState({});
  const [preview, setPreview] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [anyChange, setAnyChange] = useState(false);
  const [currentEditorImage, setCurrentEditorImage] = useState([]);
  const [deleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useModal(
    false,
  );
  const [pushDialogOpen, openPushDialog, closePushDialog] = useModal(false);
  // const [sendMessage, lastMessage, readyState, getWebSocket] = useWebSocket(socketUrl);
  const [isGalleryOpen, openGallery, closeGallery] = useModal(false);
  const [
    tagDialogOpen,
    openTagDialog,
    closeTagDialog,
    categoryDialogOpen,
    openCategoryDialog,
    closeCategoryDialog,
    relationDialogOpen,
    openRelationDialog,
    closeRelationDialog,
  ] = useModalEditors();
  const [galleryCallback, setGalleryCallback] = useState(null);

  const isNew = match.params.slug === 'new';
  useEffect(() => {
    if (!isNew) {
      setDataLoading(true);
      request('v1/news', {
        params: { slug: match.params.slug, lng: language, admin: true },
      }).then(resp => {
        setData(resp.data[0]);
      }).finally(() => {
        setDataLoading(false);
      });
    } else {
      setData({ tags: [] });
    }
    setAnyChange(false);
    resetSaveState();
  }, [match.params.slug]);

  useEffect(() => {
    if (
      !savingState?.loading &&
      savingState?.saved &&
      !pushDialogOpen &&
      data.status === 'publish'
    ) {
      // openPushDialog();
    } else if (pushDialogOpen) {
      closePushDialog();
    }
  }, [savingState]);

  const { pushCallback, isLoading } = usePhraseapp(`ids=${data?.id}`);

  useEditableViaSocket(currentUser, data, canEdit => {
    // history.goBack();
    setReadOnly(canEdit);
  });

  const { isInRole } = useCheckRole(roles);
  const isAdmin = isInRole(ROLES.ADMIN);

  const getTag = getFullTagBySlug(tags);
  const getRel = getFullTagBySlug(relations);
  const getCat = getFullCategoryBySlug(categories);

  const tagSuggestions = Object.keys(tags).map(getTag);
  const relationSuggestions = Object.keys(relations).map(getRel);
  const categoriesSuggestions = Object.keys(categories).map(getCat);

  const memoInitialValues = useMemo(() => ({
    title: data.title || '',
    content: data.content || '',
    slug: data.slug || '',
    sticky: data.sticky || false,
    comment_status: data.comment_status,
    menu_order: data.order,
    password: data.password || '',
    read_confirmation: !!data?.meta?.read_confirmation,
    tags: data.tags ? data.tags.map(getTag) : [],
    relations: data.relations ? data.relations.map(getRel) : [],
    categories: data.categories ? data.categories.map(getCat) : [],
    status: data.password ? 'password' : data.status || 'private',
    featured_media:
      data.media && data.media.image ? data.media.image.id : null,
  }), [data]);

  if (loading) return <Loader fullPage />;

  return (
    <div>
      {data.id && (
        <UnsavedPrompt
          condition={anyChange}
          message="Changes you made may not be saved."
        />
      )}
      <Header>
        <H2>{isNew ? 'Create news' : 'Editing news'}</H2>
        <div>
          <LoadingButton onClick={pushCallback} isLoading={isLoading}>
            Upload translations
          </LoadingButton>
          {data.id && <TranslationInfo data={data} />}
        </div>
      </Header>
      <Formik
        initialValues={memoInitialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          const articleData = {
            ...values,
            tags: values.tags.map(tag => tag.id),
            relations: values.relations.map(tag => tag.id),
            categories: values.categories.map(cat => cat.id),
            meta: {
              sticky: values.sticky,
              read_confirmation: values.read_confirmation ?? false,
            },
          };
          if (values.status === 'password') {
            articleData.status = 'publish';
          }
          /* if (!data.id) {
            articleData.slug = slugify(values.title);
          }
          */
          setAnyChange(false);
          saveNew(data.id, articleData);
          actions.setSubmitting(false);
        }}
        render={({ errors, touched, isSubmitting, setFieldValue, values }) => (
          <Grid>
            <Col cols={preview ? 6 : 12}>
              <Form>
                <FormikEffect onChange={setAnyChange} anyChange={anyChange} />
                <Grid>
                  <Col cols={7} style={{ overflow: 'visible' }}>
                    <div>
                      <InputLabel>Title</InputLabel>
                      <Field
                        type="text"
                        name="title"
                        size={50}
                        validate={validateTitle}
                      />
                      {errors.title && touched.title && (
                        <Error>{errors.title}</Error>
                      )}
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <StatusField
                        values={values}
                        isAdmin={isAdmin}
                        setFieldValue={setFieldValue}
                      />
                      <CommentsStatusField
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <div>
                      <InputLabel>Slug</InputLabel>
                      <Field
                        type="text"
                        name="slug"
                        size={50}
                        value={data.slug || slugify(values.title)}
                        disabled
                      />
                    </div>
                    <div>
                      <InputLabel>Order (lower goes first)</InputLabel>
                      <Field
                        type="number"
                        name="menu_order"
                        size={50}
                        min={0}
                      />
                    </div>
                    <TagsField
                      label="Tags"
                      name="tags"
                      setFieldValue={setFieldValue}
                      suggestions={tagSuggestions}
                      onOpenDialog={openTagDialog}
                    />
                    <TagsField
                      label="Categories"
                      name="categories"
                      setFieldValue={setFieldValue}
                      suggestions={categoriesSuggestions}
                      onOpenDialog={openCategoryDialog}
                    />
                    <TagsField
                      label="Relations"
                      name="relations"
                      setFieldValue={setFieldValue}
                      suggestions={relationSuggestions}
                      // onOpenDialog={openRelationDialog}
                    />
                  </Col>
                  <Col cols={5} style={{ paddingLeft: '1em' }}>
                    <AuthorInfo {...data} />
                    {!isNew && isAdmin && (
                      <RevisionsWidget
                        id={data.id}
                        current={data}
                        lastUpdate={data.updated}
                      />
                    )}
                    <FeaturedImageField
                      setFieldValue={setFieldValue}
                      currentMedia={data.media}
                    />
                    {/* ONLY FOR ADMIN */}
                    {isAdmin && (
                      <div>
                        <InputLabel>Featured?</InputLabel>
                        <Field
                          type="checkbox"
                          name="sticky"
                          value
                          checked={values.sticky}
                        />
                      </div>
                    )}
                    <div>
                      <InputLabel>Read confirmation required?</InputLabel>
                      <Field
                        type="checkbox"
                        name="read_confirmation"
                        value
                        checked={values.read_confirmation}
                      />
                      <br />
                      {values.read_confirmation ? (
                        <ReadConfirmationInfo id={data.id} />
                      ) : null}
                    </div>
                    <br />
                    <EmailNotificationButton entityId={data.id} />
                  </Col>
                </Grid>
                <InputLabel>Content</InputLabel>
                {!isDataLoading || isNew ? (
                  <div style={{ width: 830 }}>
                    <Suspense fallback={<div>Loading editor...</div>}>
                      <LazyTinyMCE
                        tinymceScriptSrc="/app/public/js/tinymce/tinymce.min.js"
                        initialValue={values.content}
                        // ref={this.props.editorRef}
                        // value={editorState}
                        init={generateSettings({
                          language,
                          setCurrentEditorImage,
                          setGalleryCallback,
                          openGallery,
                        })}
                        onChange={e => {
                          const text = e.target.getContent();
                          setFieldValue('content', text);
                        }}
                      />
                    </Suspense>
                    <small>
                      To add tooltip just type the "&lt;" character and then start
                      typing.
                      <br />
                      For example to add tooltip reffering to keyword "nozzle" you
                      can type "&lt;no" and then select Nozzle from the list.
                    </small>
                  </div>
                ) : null}
                <FormActions
                  saveDisabled={
                    readOnly ||
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    !anyChange
                  }
                  isSaving={savingState?.loading}
                  isNew={isNew}
                  previewUrl={urlBuilder({
                    language,
                    item: data,
                    params: 'preview=true',
                  })}
                  onDelete={readOnly ? null : openDeleteDialog}
                />
              </Form>
            </Col>
          </Grid>
        )}
      />
      {isGalleryOpen && (
        <>
          <Dialog
            open={isGalleryOpen}
            maxWidth="xl"
            fullWidth
            onClose={closeGallery}
            style={{ zIndex: 9999999 }}
          >
            <MediaGallery
              onImageClick={img => {
                const image = img[0];
                const media = image.media.child
                  ? image.media.child
                  : image.media;
                galleryCallback(media.original, {
                  alt: image.title ? image.title.rendered : '',
                  title: media.srcset || '',
                });
                closeGallery();
                setCurrentEditorImage([]);
              }}
              selectedItems={currentEditorImage}
            />
          </Dialog>
          <GlobalStyle />
        </>
      )}
      <DeleteModal
        open={deleteDialogOpen}
        onSuccess={() => {
          deleteNew(data.id);
          closeDeleteDialog();
        }}
        onClose={closeDeleteDialog}
      />
      <PhrasePushModal
        open={pushDialogOpen}
        onSuccess={() => {
          pushCallback();
          closePushDialog();
        }}
        onClose={closePushDialog}
      />
      <ModalEditors
        tagDialogOpen={tagDialogOpen}
        closeTagDialog={closeTagDialog}
        categoryDialogOpen={categoryDialogOpen}
        closeCategoryDialog={closeCategoryDialog}
        relationDialogOpen={relationDialogOpen}
        closeRelationDialog={closeRelationDialog}
      />
    </div>
  );
}

AdminNewPage.propTypes = {
  loadNew: PropTypes.func.isRequired,
  saveNew: PropTypes.func.isRequired,
  deleteNew: PropTypes.func.isRequired,
  initEmptyNew: PropTypes.func.isRequired,
  article: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  categories: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  relations: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  savingState: PropTypes.object.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  resetSaveState: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  language: makeSelectLocale(),
  categories: makeSelectCategoriesBySlug(),
  tags: makeSelectTagsBySlug(),
  relations: makeSelectRelationsBySlug(),
  savingState: makeSelectSavingState(),
  currentUser: makeSelectCurrentUser(),
  roles: makeSelectUserRoles(),
});

function mapDispatchToProps(dispatch) {
  return {
    // loadNew: slug => dispatch(loadNewAction(slug, true)),
    saveNew: (id, data) => dispatch(saveNewAction(id, data)),
    deleteNew: id => dispatch(deleteNewAction(id)),
    // initEmptyNew: () => dispatch(initEmptyNewAction()),
    resetSaveState: () => dispatch(setSaved(false)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(AdminNewPage);
